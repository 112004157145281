* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: #000;
  font-family: "Inter", sans-serif;
  /* Updated to Inter font */
  font-weight: 400;
  font-style: normal;
  overflow-x: hidden;
}

/* Specific styling for italic text */
.italic-text {
  font-family: "Inter", sans-serif;
  /* Updated to Inter font */
  font-weight: 400;
  font-style: italic;
}

a {
  color: black;
  text-decoration: none;
  pointer-events: all;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

canvas {
  width: 100%;
  height: 100%;
  opacity: 0;
  touch-action: auto;
  /* Allow touch actions */
  animation: fade-in 5s ease 1s forwards;
  transition: height 1s ease;
  /* Add smooth transition */
}

:root {
  --breakpoint-mobile: 375px;
  /* iPhone 14 width */
  --breakpoint-tablet: 768px;
  /* Tablet width */
  --breakpoint-desktop: 1024px;
  /* Common desktop width */
}